
import { useStore } from "@/store/store";
import VueCountdown from '@chenfengyuan/vue-countdown';
import { computed, defineComponent, ref } from 'vue';
import Loading from "vue-loading-overlay";
import SocialNetworkComponent from "../components/SocialNetworkComponent.vue";
import WalletConnectionHandler from "../components/WalletConnectionHandler.vue";
import useDogggoContract from "../composables/useDogggoContract";
import useImmutableX from "../composables/useImmutableX";
import useLoadingHandler from "../composables/useLoadingHandler";
import useNetwork from "../composables/useNetwork";
import useNFTContract from "../composables/useNFTContract";
import { BINANCE_NETWORK_ID, BINANCE_NETWORK_ID_HEX, ETH_NETWORK_ID, URLS } from "../constants";
import { useGtm } from '@gtm-support/vue-gtm';

export default defineComponent({
    name: "BuyView",
    components: {
        WalletConnectionHandler,
        VueCountdown,
        SocialNetworkComponent,
        Loading
    },
    setup: async () => {
        const buyloader = ref();
        const approvebutton = ref();
        const dgbuyform = ref();
        const successmessage = ref();
        const errormessage = ref();
        const notenoughfounds = ref();
        const transactionrejected = ref();
        const errorMintingNFTs = ref();
        const errorRegisteringImmutableX = ref();
        const buybutton = ref();

        let amountNFTToBuy = '';
        const numberOfBillionsPerAllNFT = ref('');
        const numberOfBillionsPerNFT = ref(0);

        const store = useStore();
        const { doConnectNetwork, switchNetwork, connectToNetwork } = await useNetwork(store);
        await doConnectNetwork();
        const { registerImmutableX } = await useImmutableX();

        const gtm = useGtm();

        const binanceNetworkID = Number(BINANCE_NETWORK_ID);
        const {
            getNFTSold, getNFTAvailable, getOpeningTime,
            getNFTReserved, getNFTToDogggoConversion,
            reserveNFT, getDogggoCollected
        } = await useNFTContract(store.state);

        const { getMyBalance, approveTokens } = await useDogggoContract(store.state);

        const { toggleLoading } = useLoadingHandler();

        const doApproveTokens = async () => {
            try {
                buyloader.value.style.display = 'block';
                approvebutton.value.style.display = 'none';

                await approveTokens();
                store.commit("setApprovetokens", true);
                localStorage.setItem('tokensApproved', 'true');
            } catch (error) {
                console.log(error);
                console.log('User rejected token approval.');
            } finally {
                buyloader.value.style.display = 'none';
                approvebutton.value.style.display = 'block';
            }
        };

        const updateCosts = async (valueInput: any) => {
            let totalValue = 0;
            if (valueInput.target.value > 0) {
                if (buybutton.value != undefined) {
                    buybutton.value.style.display = 'none';
                    buybutton.value.innerText = 'Mint NFTs';
                }
                buyloader.value.style.display = 'block';
                amountNFTToBuy = valueInput.target.value;
                totalValue = await getNFTToDogggoConversion(valueInput.target.value);
                numberOfBillionsPerAllNFT.value = Math.round(totalValue / (10 ** 18)).toLocaleString('en');
                numberOfBillionsPerNFT.value = Math.round(totalValue / (valueInput.target.value * 10 ** 27));
                if (+totalValue > +store.state.myBalance) {
                    notenoughfounds.value.style.display = 'block';
                    if (buybutton.value != undefined) {
                        buybutton.value.style.display = 'none';
                    }
                } else {
                    notenoughfounds.value.style.display = 'none';
                    if (buybutton.value != undefined) {
                        buybutton.value.style.display = 'block';
                    }
                }
            } else {
                numberOfBillionsPerAllNFT.value = '';
                numberOfBillionsPerNFT.value = 0;
                notenoughfounds.value.style.display = 'none';
                if (buybutton.value != undefined) {
                    buybutton.value.innerText = 'Add NFTs to Mint';
                    buybutton.value.style.display = 'block';
                }
            }
            buyloader.value.style.display = 'none';
        };

        const retryFailedMints = async () => {
        }

        const buyNFT = async () => {
            if (numberOfBillionsPerAllNFT.value.length > 0) {
                if (gtm != undefined) {
                    gtm.trackEvent({
                        event: 'User clicks buy button',
                        category: 'purchase-process',
                        action: 'click',
                        label: 'User clicks buy button and start the purchase process.',
                        value: 5003,
                        noninteraction: false,
                    });
                }
                let numberOfBillionsPerAllNFTNoComas = numberOfBillionsPerAllNFT.value.replaceAll(',', '')
                if (Math.round(store.state.myBalance / (10 ** 18)) >= +numberOfBillionsPerAllNFTNoComas) {
                    dgbuyform.value.style.display = 'none';
                    buyloader.value.style.display = 'block';
                    errormessage.value.style.display = 'none';
                    successmessage.value.style.display = 'none';
                    transactionrejected.value.style.display = 'none';
                    errorMintingNFTs.value.style.display = 'none';
                    errorRegisteringImmutableX.value.style.display = 'none';
                    try {
                        await reserveNFT(amountNFTToBuy);

                        await new Promise(r => setTimeout(r, 5000));

                        await doUpdateView();
                        dgbuyform.value.style.display = 'block';
                        buyloader.value.style.display = 'none';
                        successmessage.value.style.display = 'block';

                        try {
                            const res = await fetch("https://api.dogggo.io/v1/mintImxNft", {
                                method: 'POST',
                                body: new URLSearchParams({
                                    'userAddress': store.state.currentUser,
                                })
                            });
                            if (gtm != undefined) {
                                gtm.trackEvent({
                                    event: 'Purchase completed',
                                    category: 'purchase-process',
                                    action: 'click',
                                    label: 'User clicks on buy button and purchase completed',
                                    value: 5001,
                                    noninteraction: false,
                                });
                            }

                        } catch (err: any) {
                            dgbuyform.value.style.display = 'block';
                            buyloader.value.style.display = 'none';
                            errorRegisteringImmutableX.value.style.display = 'block';
                        }
                    } catch (err: any) {
                        if (err != null && err.code == 4001) {
                            // Transaction rejected by the user
                            dgbuyform.value.style.display = 'block';
                            buyloader.value.style.display = 'none';
                            transactionrejected.value.style.display = 'block';
                        } else {
                            // Something went wrong
                            dgbuyform.value.style.display = 'block';
                            buyloader.value.style.display = 'none';
                            errormessage.value.style.display = 'block';
                        }
                    }
                }
            }
        };

        const doSwitchToBSCNetwork = async () => {
            const bscNetworkHexID = BINANCE_NETWORK_ID_HEX;
            const bscNetworkID = BINANCE_NETWORK_ID;
            await switchNetwork(bscNetworkHexID, bscNetworkID);
        };

        const doSwitchToETHNetwork = async () => {
            const ethNetworkID = ETH_NETWORK_ID;
            await switchNetwork(ethNetworkID, ethNetworkID);
        };

        const doUpdateView = async () => {
            let NFTSold: string = 'XXX';
            let dogggoCollected: number = 0;
            let NFTAvailable: string = 'XXX';
            let openingTime: number = 0;
            let myBalance: number = 0;
            let myNFTReserved: number = 0;
            if (store.state.chainId === binanceNetworkID) {
                try {
                    [NFTSold, dogggoCollected, NFTAvailable, openingTime, myBalance, myNFTReserved] = await Promise.all([
                        getNFTSold(),
                        getDogggoCollected(),
                        getNFTAvailable(),
                        getOpeningTime(),
                        getMyBalance(),
                        getNFTReserved()
                    ]);
                } catch (err) {
                    console.log('ERROR Requesting info to the blockchain.');
                }
            } else {
                console.log("ERROR metamask chainID is not Binance ID.");
            }

            store.commit("setNFTSold", NFTSold);
            store.commit("setDogggoCollected", dogggoCollected);
            store.commit("setNFTAvailable", NFTAvailable);

            //store.commit("setOpeningTime", openingTime);
            //store.commit("setOpeningTime", 1653312275);
            store.commit("setOpeningTime", 0);

            store.commit("setMyBalance", myBalance);
            store.commit("setMyNFTReserved", myNFTReserved);
        };

        if (localStorage.getItem('address') != null) {
            store.commit("setIMXRegistered", true);
        } else {
            store.commit("setIMXRegistered", false);
        }

        if (localStorage.getItem('tokensApproved') != null) {
            store.commit("setApprovetokens", true);
        } else {
            store.commit("setApprovetokens", false);
        }

        await doUpdateView();
        toggleLoading('main-loader', false);

        return {
            chainId: computed(() => store.state.chainId),
            selectedAccount: computed(() => store.state.currentUser),
            nftSoldComputed: computed(() => store.state.NFTSold),
            dogggoCollectedComputed: computed(() => Math.round(store.state.dogggoCollected / (10 ** 27))),
            NFTAvailableComputed: computed(() => store.state.NFTAvailable),
            openingTimeComputed: computed(() => (store.state.openingTime - Math.floor(Date.now() / 1000)) * 1000),
            myBalanceComputed: computed(() => Math.round(store.state.myBalance / (10 ** 18)).toLocaleString('en')),
            myNFTReservedComputed: computed(() => store.state.myNFTReserved),
            isIMXRegistered: computed(() => store.state.isIMXRegistered),
            isTokenApproved: computed(() => store.state.isTokensApproved),
            connectToNetwork,
            updateCosts,
            numberOfBillionsPerAllNFT,
            numberOfBillionsPerNFT,
            buyNFT,
            registerImmutableX,
            doSwitchToBSCNetwork,
            doSwitchToETHNetwork,
            doApproveTokens,
            buyloader,
            approvebutton,
            dgbuyform,
            successmessage,
            errormessage,
            notenoughfounds,
            transactionrejected,
            errorMintingNFTs,
            errorRegisteringImmutableX,
            buybutton,
            amountNFTToBuy,
            BINANCE_NETWORK_ID,
            URLS,
            ETH_NETWORK_ID,
            retryFailedMints
        };
    },
});

