import { State } from "@/store/store";
import { AbiItem } from 'web3-utils';
import genericContractAbi from "../contracts/GenericToken.json";
import { DOGGGO_ADDRESS, DOGGGO_NFT_ADDRESS } from "../constants";
import web3 from 'web3';


export default async function useDogggoContract(state: State) {
    const getDogggoContract = () => new state.web3.eth.Contract(genericContractAbi as AbiItem[], DOGGGO_ADDRESS);

    const getMyBalance = async () => await getDogggoContract().methods.balanceOf(state.currentUser).call();
    const approveTokens = async () => {
        let approve_amount = '115792089237316195423570985008687907853269984665640564039457584007913129639935';
        return await getDogggoContract().methods.approve(DOGGGO_NFT_ADDRESS, approve_amount).send({
            from: state.currentUser,
        });
    }
    const allowance = async () => await getDogggoContract().methods.allowance(state.currentUser, DOGGGO_NFT_ADDRESS).call();

    return {
        getMyBalance,
        approveTokens,
        allowance
    }
}

